import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				A propos de nous | A propos de Seventic
			</title>
			<meta name={"description"} content={"Explorez les rouages de Seventic, votre porte d'entrée vers des solutions complètes de marketing numérique."} />
			<meta property={"og:title"} content={"A propos de nous | A propos de Seventic"} />
			<meta property={"og:description"} content={"Explorez les rouages de Seventic, votre porte d'entrée vers des solutions complètes de marketing numérique."} />
			<meta property={"og:image"} content={"https://frenchriviera.live/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://frenchriviera.live/media/551-5512749.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://frenchriviera.live/media/551-5512749.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://frenchriviera.live/media/551-5512749.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://frenchriviera.live/media/551-5512749.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://frenchriviera.live/media/551-5512749.png"} />
			<meta name={"msapplication-TileImage"} content={"https://frenchriviera.live/media/551-5512749.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Naviguer dans Seventic
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Explorez les rouages de Seventic, votre porte d'entrée vers des solutions complètes de marketing numérique. En mettant l'accent sur l'innovation, la stratégie et les résultats mesurables, nous nous engageons à propulser votre marque vers de nouveaux sommets dans le paysage numérique. Préparez-vous à plonger dans les détails de nos services, de notre équipe et de l'approche unique qui distingue Seventic dans le domaine du marketing en ligne.
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Découvrez l'histoire de Seventic
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Chez Seventic, nous naviguons dans le paysage numérique avec une fusion d'innovation, de stratégie et une touche de flair créatif. Née de la volonté de redéfinir les règles du marketing numérique, Seventic est devenue un centre d'expertise où les marques prospèrent dans l'arène en ligne. Notre parcours est tissé d'expériences, de leçons et d'une poursuite incessante de l'excellence numérique.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://frenchriviera.live/media/5.jpg"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://frenchriviera.live/media/6.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 0px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Au-delà de la narration
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Lorsque nous racontons l'histoire de Seventic, il ne s'agit pas seulement de notre parcours, mais aussi des étapes que nous avons aidées nos clients à franchir. Au-delà de l'histoire, il y a un engagement pour votre succès, soutenu par une équipe de professionnels passionnés, animés par un objectif commun. Rejoignez-nous pour découvrir comment Seventic va au-delà de la narration, en traduisant les idées en stratégies numériques percutantes qui résonnent et conduisent à des résultats réels et tangibles.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 0px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Ce qui nous distingue
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					- Des prouesses stratégiques : Nous abordons chaque campagne comme un voyage unique, en élaborant une stratégie qui s'aligne sur l'identité et les objectifs de votre marque.
					<br />
					<br />
					- Ingéniosité créative : Notre équipe d'esprits créatifs donne vie aux idées, garantissant que votre marque se démarque dans l'espace numérique encombré.
					<br />
					<br />
					- Des idées basées sur des données : Nous ne nous contentons pas de créer, nous analysons. Notre approche basée sur les données garantit que chaque campagne est optimisée pour le succès.
				</Text>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://frenchriviera.live/media/7.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="40%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Seventic{" "}
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						Là où l'innovation rencontre l'impact, redéfinissant vos horizons numériques
Nous vous invitons à prendre contact avec nous et à tracer ensemble la voie de votre réussite numérique. Votre histoire, votre marque et notre expertise - un partenariat propice à une croissance extraordinaire.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});